'use client'

import type { PropsWithChildren } from 'react'

import Image from 'next/image'

import type { StaticImport } from 'next/dist/shared/lib/get-img-props'

export type CarouselItemProps = {
  step?: number
  img?: string | StaticImport
} & PropsWithChildren
export const CarouselItem: React.FC<CarouselItemProps> = ({
  step,
  img,
  children,
}) => {
  return (
    <figure
      className="flex min-w-full flex-col items-center justify-start transition duration-500 ease-in-out motion-reduce:transition-none"
      style={{ transform: `translateX(-${(step ?? 0) * 100}%)` }}
    >
      {img != null ? (
        <Image
          alt=""
          className="h-screen max-h-[60vh] w-full object-contain"
          loading="eager"
          quality={60}
          src={img}
        />
      ) : null}
      <figcaption className="mt-6 w-full text-left">{children}</figcaption>
    </figure>
  )
}
