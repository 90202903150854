'use client'

import type { PropsWithChildren } from 'react'

import {
  CWAccordionPanel,
  CWAccordionPanelContent,
  CWAccordionPanelHeader,
} from '../ComponentsWeb/Accordion'

/**
 * Props for the VideoContent component.
 */
export interface VideoContentProps extends PropsWithChildren {
  /**
   * The header text for the accordion panel.
   */
  header: string
  /**
   * The URL of the video to be embedded.
   */
  url: string
}

/**
 * A component that displays video content within an accordion panel.
 * @param props - The component props.
 * @returns A React component.
 */
export const VideoContent: React.FC<VideoContentProps> = ({
  children,
  header,
  url,
}) => {
  return (
    <CWAccordionPanel onCard={false}>
      <CWAccordionPanelHeader>
        <div className="text-interactive hover:text-blue-400 text-left font-circular-medium text-lg">
          {header}
        </div>
      </CWAccordionPanelHeader>
      <CWAccordionPanelContent>
        <div className="w-full space-y-4">
          <div>{children}</div>
          <iframe
            className="aspect-video w-full"
            src={url}
            title={header}
            allowFullScreen
          />
        </div>
      </CWAccordionPanelContent>
    </CWAccordionPanel>
  )
}
