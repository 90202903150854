import { useEffect, useMemo, useState } from 'react'

import { usePlaygroundContext } from './PlaygroundProvider'

import type { Field } from './types'

export interface FieldResponse {
  fields: Omit<Field, 'value'>[]
  identifier: string
  endpoint: string
  method: 'GET' | 'POST'
}

export interface usePlaygroundDataProps {
  url: string
  _type?: string
  _methodName?: string
}

/**
 *
 */
export function usePlaygroundData({
  url,
  _methodName,
  _type,
}: usePlaygroundDataProps) {
  const [{ data: fieldCache, initiator }, { updateFieldCache }] =
    usePlaygroundContext()
  const [loading, setLoading] = useState(true)
  const [fields, setFields] = useState<Omit<Field, 'value'>[]>([])
  const [identifier, setIdentifier] = useState('')
  const [endpoint, setEndpoint] = useState('')
  const [method, setMethod] = useState<'GET' | 'POST'>('GET')
  const [result, setResult] = useState<object | string | null>(null)

  useEffect(() => {
    const fetchFields = async () => {
      const response = await fetch(`${url}?type=${_type}&name=${_methodName}`)

      const { fields, identifier, endpoint, method } =
        (await response.json()) as FieldResponse
      setIdentifier(identifier)
      setFields(fields)
      setLoading(false)
      setEndpoint(endpoint)
      setMethod(method)
    }

    void fetchFields()
  }, [_methodName, _type, url, initiator])

  return useMemo(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    () => ({
      fields: fields.map((field) => ({
        ...field,
        value:
          field.key in fieldCache
            ? fieldCache[field.key]
            : field.type === 'multiselect'
              ? []
              : (field.defaultValue ?? ''),
      })) as Field[],
      identifier,
      endpoint,
      method,
      handleFieldChange: updateFieldCache,
      handleSubmit: async (values: Record<string, unknown>) => {
        setLoading(true)

        const params = Object.keys(values).reduce((result, key) => {
          const field = fields.find(({ key: fieldKey }) => key === fieldKey)

          let value: string | number | string[]
          switch (field?.type) {
            case 'multiselect':
              value = (values[key] as { value: string }[]).map(
                ({ value }) => value,
              )
              break
            case 'number':
              value = parseInt(values[key] as string)
              break
            default:
              value = values[key] as string
          }

          updateFieldCache(key, value)

          return {
            ...result,
            [key]: value,
          }
        }, {})

        try {
          const response = await fetch(url + `?initiator=${initiator}`, {
            method: 'POST',
            body: JSON.stringify({ ...params, _methodName, _type }),
          })
          if (response.headers.get('content-type') === 'text/plain') {
            setResult(await response.text())
          } else {
            setResult((await response.json()) as object)
          }
        } catch (e: unknown) {
          const error = e as { message?: unknown }
          if ('message' in error) {
            setResult(JSON.stringify(error.message, null, 2))
          } else {
            setResult(error)
          }
        }
        setLoading(false)
      },
      result,
      loading,
      handleRetry: () => {
        setResult(null)
      },
    }),
    [
      fields,
      identifier,
      endpoint,
      method,
      updateFieldCache,
      result,
      loading,
      fieldCache,
      url,
      _methodName,
      _type,
      initiator,
    ],
  )
}
