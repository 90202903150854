'use client'

import type { Dispatch, PropsWithChildren, SetStateAction } from 'react'
import { createContext, useContext, useState } from 'react'

import type { LanguageType } from '../languages'

const CodeGroupContext = createContext<
  [LanguageType, Dispatch<SetStateAction<LanguageType>>] | undefined
>(undefined)

export const CodeGroupProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const state = useState<LanguageType>('node')

  return (
    <CodeGroupContext.Provider value={state}>
      {children}
    </CodeGroupContext.Provider>
  )
}

export const useCodeGroupContext = () => {
  return useContext(CodeGroupContext)
}
