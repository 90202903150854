import type { FC } from 'react'

import type { BasicInputField } from './types'

export const ApiKeyInput: FC<BasicInputField> = ({ InputComponent, field }) => {
  return (
    <InputComponent
      className="w-full"
      name={field.key}
      placeholder="e.g. TEST_API_KEY:xxxxxx:xxxxxx"
      {...field}
      key={field.key}
    />
  )
}
