import type { FC } from 'react'

// eslint-disable-next-line import/no-cycle
import { InputSelector } from './InputSelector'

import type { InputSelectorProps } from './InputSelector'
import type { GroupInputField } from './types'
import type { Field } from '../types'

export const GroupInput: FC<
  GroupInputField & {
    entries: Field[][]
  } & InputSelectorProps
> = ({ field, entries, Form, formMethods }) => {
  return (
    <div>
      {field.label}
      <div className="flex flex-col divide-y-2 divide-neutral-subtle space-y-4 ml-4">
        {entries.map((group, index) => (
          <div key={index}>
            <InputSelector
              Form={Form}
              fields={group}
              formMethods={formMethods}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
