import type { Fields, SmartFuncs } from './types'

export const smartFields: Pick<Fields<SmartFuncs>, 'listContracts'> = {
  listContracts: {
    apiKey: true,
    entitySecret: false,
    endpoint: 'https://api.circle.com/v1/w3s/contracts',
    method: 'GET',
    fields: {
      blockchain: {
        type: 'select',
        key: 'blockchain',
        label: 'Blockchain',
        options: ['ETH', 'ETH-GEORLI', 'ETH-SEPOLIA', 'MATIC', 'MATIC-AMOY'],
        paramType: 'body',
        required: false,
      },
    },
  },
}
