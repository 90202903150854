import type { FC } from 'react'

import type { BasicSelectField } from './types'

export const SelectInput: FC<BasicSelectField> = ({
  InputComponent,
  field,
}) => {
  return (
    <InputComponent
      key={field.key}
      items={field.options.map((option) => ({
        label: option,
        value: option,
      }))}
      label={field.label}
      message={field.message ?? (field.required ? '' : 'Optional')}
      name={field.key}
    />
  )
}
