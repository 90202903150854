import { usePlaygroundData } from '../usePlaygroundData'

import { FormComp } from './Form'
import { Loading } from './Loading'
import { Result } from './Result'

import type { CopyParams } from '../Playground'

export interface ContentProps {
  /**
   * API url for specific playground.
   */
  url: string
  /**
   * Config for isolated copy values.
   */
  copy?: CopyParams
  /**
   * Explanation that will be displayed next to the isolated result.
   */
  copyExplanation?: string
}

export const Content: React.FC<ContentProps> = ({ copy, url }) => {
  const { fields, loading, result, handleRetry, handleSubmit, identifier } =
    usePlaygroundData(url)

  if (loading) return <Loading />

  if (result !== null)
    return (
      <Result
        copy={copy}
        handleRetry={handleRetry}
        identifier={identifier}
        result={result}
      />
    )

  return (
    <FormComp fields={fields} identifier={identifier} onSubmit={handleSubmit} />
  )
}
