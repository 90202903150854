import type { FC } from 'react'

import useTranslation from 'next-translate/useTranslation'

import type { BasicMultiSelectField } from './types'

const emptyFunction = () => undefined

export const MultiSelectInput: FC<BasicMultiSelectField> = ({
  InputComponent,
  field,
}) => {
  const { t } = useTranslation('common')

  return (
    <InputComponent
      items={field.options.map((option) => ({
        label: option,
        value: option,
      }))}
      locale={{
        clearButtonLabel: t`forms.multiSelect.clearButtonLabel`,
        noResultsMessage: t`forms.multiSelect.noResultsMessage`,
        noItemsMessage: t`forms.multiSelect.noItemsMessage`,
      }}
      message={field.message ?? (field.required ? '' : 'Optional')}
      name={field.key}
      onInputChange={emptyFunction}
      searchTerm=""
      {...field}
      key={field.key}
    />
  )
}
