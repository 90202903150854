import { feeField, idempotencyKeyField } from './commonFields'

import type { Fields, DevFuncs } from './types'

// temp type, should be later changed to just `Fields<DevFuncs>`
export const devFields: Pick<
  Fields<DevFuncs>,
  | 'accelerateTransaction'
  | 'cancelTransaction'
  | 'createContractExecutionTransaction'
  | 'listTransactions'
  | 'createTransaction'
> = {
  accelerateTransaction: {
    method: 'POST',
    apiKey: true,
    entitySecret: true,
    endpoint:
      'https://api.circle.com/v1/w3s/developer/transactions/{id}/accelerate',
    fields: {
      id: {
        paramType: 'path',
        key: 'id',
        label: 'Id',
        required: true,
        type: 'string',
      },
      idempotencyKey: idempotencyKeyField,
    },
  },
  cancelTransaction: {
    method: 'POST',
    apiKey: true,
    entitySecret: true,
    endpoint:
      'https://api.circle.com/v1/w3s/developer/transactions/{id}/cancel',
    fields: {
      id: {
        key: 'id',
        label: 'Id',
        required: true,
        type: 'string',
        paramType: 'path',
      },
      idempotencyKey: idempotencyKeyField,
    },
  },
  createContractExecutionTransaction: {
    apiKey: true,
    entitySecret: true,
    method: 'POST',
    endpoint:
      'https://api.circle.com/v1/w3s/developer/transactions/contractExecution',
    fields: {
      contractAddress: {
        key: 'contractAddress',
        label: 'ContractAddress',
        type: 'string',
        paramType: 'body',
        required: true,
      },
      fee: feeField,
      walletId: {
        key: 'walletId',
        type: 'string',
        paramType: 'body',
        label: 'WalletId',
        required: false,
      },
      abiFunctionSignature: {
        key: 'abiFunctionSignature',
        type: 'string',
        paramType: 'body',
        label: 'AbiFunctionSignature',
        required: false,
      },
      abiParameters: {
        type: 'string',
        key: 'abiParameters',
        label: 'AbiParameters',
        required: true,
        paramType: 'body',
      },
      amount: {
        paramType: 'body',
        type: 'number',
        key: 'amount',
        label: 'Amount',
        required: true,
      },
      idempotencyKey: idempotencyKeyField,
    },
  },
  createTransaction: {
    endpoint: 'https://api.circle.com/v1/w3s/developer/transactions/transfer',
    method: 'POST',
    apiKey: true,
    entitySecret: true,
    fields: {
      destinationAddress: {
        key: 'destinationAddress',
        label: 'Destination Address',
        type: 'string',
        paramType: 'body',
        required: true,
      },
      idempotencyKey: idempotencyKeyField,
      amount: {
        paramType: 'body',
        type: 'string',
        multiple: true,
        key: 'amount',
        label: 'Amount',
        required: true,
      },
      fee: feeField,
      tokenId: {
        key: 'tokenId',
        type: 'string',
        paramType: 'body',
        label: 'TokenId',
        required: true,
      },
      walletId: {
        key: 'walletId',
        type: 'string',
        paramType: 'body',
        label: 'WalletId',
        required: true,
      },
    },
  },
  //   createWallets: '',
  //   createWalletSet: '',
  //   estimateContractExecutionFee: '',
  //   estimateTransferFee: '',
  //   getPublicKey: '',
  //   getToken: '',
  //   getTransaction: '',
  //   getWallet: '',
  //   getWalletNFTBalance: '',
  //   getWalletSet: '',
  //   getWalletTokenBalance: '',
  listTransactions: {
    endpoint: 'https://api.circle.com/v1/w3s/developer/transactions',
    method: 'GET',
    apiKey: true,
    entitySecret: false,
    fields: {
      operation: {
        key: 'operation',
        label: 'Operation',
        type: 'select',
        options: ['TRANSFER', 'CONTRACT_EXECUTION', 'CONTRACT_DEPLOYMENT'],
        required: false,
        paramType: 'body',
      },
    },
  },
  //   listWallets: '',
  //   listWalletSets: '',
  //   signMessage: '',
  //   signTypedData: '',
  //   updateWallet: '',
  //   updateWalletSet: '',
  //   validateAddress: '',
}
