import { useEffect, type FC } from 'react'

import type { StaticInputField } from './types'
import type { useForm } from '@circlefin/form'

export const StaticInput: FC<
  StaticInputField & { name: string; content?: string } & {
    formMethods: ReturnType<typeof useForm>['1']
  }
> = ({ InputComponent, field, name, formMethods }) => {
  useEffect(() => {
    formMethods.setValue('type', 'true')
  }, [formMethods, field.key, name])
  return (
    <InputComponent
      className="w-full"
      name={field.key}
      {...field}
      key={field.key}
      type="string"
      disabled
    />
  )
}
