import type { FC } from 'react'
import { useCallback } from 'react'

import { Input } from '@circlefin/components'
import { v4 as uuid } from 'uuid'

import type { BasicInputField } from './types'
import type { useForm } from '@circlefin/form'

export const InputIdempotencyKey: FC<
  BasicInputField & {
    name: string
    formMethods: ReturnType<typeof useForm>['1']
  }
> = ({ InputComponent, field, name, formMethods }) => {
  const handleClick = useCallback(() => {
    formMethods.setValue(field.key, uuid())
  }, [field.key, formMethods])

  return (
    <InputComponent className="w-full" name={name} {...field} key={field.key}>
      <Input.Inline.IconButton
        align="right"
        aria-label="s"
        label="Add Idempotency Key"
        name="PlusSolid"
        onClick={handleClick}
      />
    </InputComponent>
  )
}
