'use client'

import { useEffect, useState } from 'react'

import { usePreventLayoutShift } from '../../../hooks'

import type { LanguageType } from '../languages'

export const useTabProps = (availableLanguages: LanguageType[]) => {
  const preferredLanguage: LanguageType = 'node'
  const [selectedIndex, setSelectedIndex] = useState(0)

  useEffect(() => {
    const newSelectedIndex = availableLanguages.indexOf(preferredLanguage)
    if (newSelectedIndex !== -1 && newSelectedIndex !== selectedIndex) {
      setSelectedIndex(newSelectedIndex)
    }
    // Run on initial render to set preferred language only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { positionRef, preventLayoutShift } = usePreventLayoutShift()

  return {
    as: 'div',
    ref: positionRef,
    selectedIndex,
    onChange: (newSelectedIndex: number) => {
      preventLayoutShift(() => {
        setSelectedIndex(newSelectedIndex)
      })
    },
  }
}
