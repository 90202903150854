export const ThumbsDown = () => {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon/Outline/thumb-up">
        <path
          d="M11.6667 11.6667H15.6366C16.8756 11.6667 17.6814 10.3628 17.1273 9.25464L14.2107 3.42131C13.9283 2.85667 13.3512 2.5 12.7199 2.5H9.37184C9.23557 2.5 9.09982 2.51671 8.96762 2.54976L5.83333 3.33333M11.6667 11.6667V15.8333C11.6667 16.7538 10.9205 17.5 10 17.5H9.92044C9.50414 17.5 9.16667 17.1625 9.16667 16.7462C9.16667 16.151 8.99047 15.569 8.66028 15.0738L5.83333 10.8333V3.33333M11.6667 11.6667H10M5.83333 3.33333H4.16667C3.24619 3.33333 2.5 4.07952 2.5 5V10C2.5 10.9205 3.24619 11.6667 4.16667 11.6667H6.25"
          id="Icon"
          stroke="#6B6580"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  )
}
