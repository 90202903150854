'use client'

import { useEffect } from 'react'

import { usePlaygroundContext } from './PlaygroundProvider'

export interface SetInitiatorProps {
  initiator: string
}
export const SetInitiator: React.FC<SetInitiatorProps> = ({ initiator }) => {
  const [, { setInitiator }] = usePlaygroundContext()
  useEffect(() => {
    setInitiator(initiator)
  }, [initiator, setInitiator])
  return null
}
