'use client'

import { useCallback, useMemo, useState } from 'react'

import { useForm, y } from '@circlefin/form'
import { useConfig } from '@features/common.hooks/useConfig'
import { devFields } from '@shared/api/lib/devFields'
import { smartFields } from '@shared/api/lib/smartFields'
import { userFields } from '@shared/api/lib/userFields'
import { notFound } from 'next/navigation'

import { PlaygroundV2 } from '../Playgroundv2/Playground'
import { PlaygroundProvider } from '../Playgroundv2/PlaygroundProvider'

import type { SubmitHandler } from '@circlefin/form/useForm'

const schema = y.object().shape({
  client: y.string().oneOf(['DEV', 'USER', 'SMART']).required(),
  method: y.string().required(),
})

type FormSchema = y.InferType<typeof schema>

export const SDKPlayground = () => {
  const { env } = useConfig()

  const [Form, { watch }] = useForm<FormSchema>({
    schema,
    defaultValues: { client: 'DEV' },
  })

  const watchedClient = watch('client')

  const [client, setClient] = useState('DEV')
  const [method, setMethod] = useState('')

  const handleSubmit: SubmitHandler<FormSchema> = useCallback(
    ({ client, method }) => {
      setClient(client)
      setMethod(method)
    },
    [],
  )

  const possibleFields = useMemo(() => {
    switch (watchedClient) {
      case 'DEV':
        return Object.keys(devFields).map((field) => ({
          label: field,
          value: field,
        }))
      case 'USER':
        return Object.keys(userFields).map((field) => ({
          label: field,
          value: field,
        }))
      case 'SMART':
        return Object.keys(smartFields).map((field) => ({
          label: field,
          value: field,
        }))
      default:
        throw new Error('Invalid Client')
    }
  }, [watchedClient])

  if (env === 'prod') {
    return notFound()
  }

  return (
    <div className="p-6">
      <Form
        className="border border-neutral-subtle p-4 rounded-sm mb-2 flex space-y-4 flex-col"
        onSubmit={handleSubmit}
      >
        <Form.Dropdown
          items={[
            { label: 'DEV', value: 'DEV' },
            { label: 'USER', value: 'USER' },
            { label: 'SMART', value: 'SMART' },
          ]}
          label="client"
          name="client"
        />

        <Form.Dropdown items={possibleFields} label="method" name="method" />
        <Form.SubmitButton variant="primary">SHOW</Form.SubmitButton>
      </Form>

      <PlaygroundProvider>
        {client && method ? (
          <PlaygroundV2
            _methodName={method}
            _type={client}
            title="Playground TIO"
            url="/api/playground"
          />
        ) : null}
      </PlaygroundProvider>
    </div>
  )
}
