import { HTTPSnippet } from '@readme/httpsnippet'

import type { RequestConfig, RequestQuery, RequestQueryParam } from './types'
import type { HarRequest } from '@readme/httpsnippet'

interface Param {
  name: string
  value: string
}

export const getCode = async (
  config: RequestConfig,
  apiUrl: string,
  target?: string,
  client?: string,
) => {
  if (client && target === 'SDK') {
    return getSDKCodeExample(client, config.method, config.url)
  }
  const snippetParams: HarRequest = {
    method: config.method,
    url: new URL(config.url, apiUrl).href,
    headers: Object.keys(config.headers ?? {}).map((key) => ({
      name: key,
      value: config.headers?.[key] ?? '',
    })),
    queryString: buildParams(config.query),
    postData: {
      mimeType: 'application/json',
      text: JSON.stringify(config.body),
    },
    httpVersion: 'HTTP/1.1',
    cookies: [],
    headersSize: -1,
    bodySize: -1,
  }

  const snippet = new HTTPSnippet(snippetParams)

  return await snippet.convert((target as never) ?? 'node', client)
}

export const buildParams = (query: RequestQuery = {}) => {
  const params: Param[] = []

  Object.keys(query).forEach((key) => {
    appendParam(params, key, query[key])
  })

  return params
}

const appendParam = (
  params: Param[],
  key: string,
  param: RequestQueryParam,
) => {
  if (Array.isArray(param)) {
    param.forEach((p) => appendParam(params, key, p))
  } else if (typeof param === 'string') {
    params.push({
      name: key,
      value: param,
    })
  } else {
    params.push({
      name: key,
      value: param.toString(),
    })
  }
}

const nodeDCWInitializeSnippet = `// Import & Initialize
import { initiateDeveloperControlledWalletsClient } from '@circle-fin/developer-controlled-wallets';
const client = initiateDeveloperControlledWalletsClient({
  apiKey: '<API_KEY>',
  entitySecret: '<ENTITY_SECRET>'
});

`
const nodeUCWInitializeSnippet = `// Import & Initialize
import { initiateUserControlledWalletsClient } from '@circle-fin/user-controlled-wallets';
const client = initiateUserControlledWalletsClient({
  apiKey: '<API_KEY>',
});

`
const pythonDCWInitializeSnippet = `from circle.web3 import developer_controlled_wallets
from circle.web3 import utils

client = utils.init_developer_controlled_wallets_client(api_key="<your-api-key>", entity_secret="<your-entity-secret>")
`
const pythonUCWInitializeSnippet = `from circle.web3 import user_controlled_wallets
from circle.web3 import utils

client = utils.init_user_controlled_wallets_client(api_key="<your-api-key>")
`
const nodeSDKCodeExamplesMap: { [key: string]: string } = {
  //DCW
  'GET/v1/w3s/config/entity/publicKey':
    nodeDCWInitializeSnippet + `const response = await client.getPublicKey();`,
  'POST/v1/w3s/developer/walletSets':
    nodeDCWInitializeSnippet +
    `const response = await client.createWalletSet({
  name: '<WALLET_SET_NAME>'
});`,
  'POST/v1/w3s/developer/wallets':
    nodeDCWInitializeSnippet +
    `const response = await client.createWallets({
  blockchains: ['<BLOCKCHAIN_1>','<BLOCKCHAIN_2>','<BLOCKCHAIN_3>'],
  count: 2,
  walletSetId: '<ID_OF_PREVIOUSLY_GENERATED_WALLET_SET>'
});`,
  'GET/v1/w3s/wallets/__WALLET_ID__/balances': `const response = await client.getWalletTokenBalance({
  id: '<ID_OF_PREVIOUSLY_GENERATED_WALLET>'
});`,
  'POST/v1/w3s/developer/transactions/transfer':
    nodeDCWInitializeSnippet +
    `const response = await client.createTransaction({
  walletId: '<ID_OF_PREVIOUSLY_GENERATED_WALLET>',
  tokenId: '<ID_OF_THE_TOKEN_YOU_WANT_TO_TRANSFER>',
  destinationAddress: '<ADDRESS_OF_THE_DESTINATION_WALLET>',
  amounts: ['<AMOUNT_1>'],
  fee: {
    type: 'level',
    config: {
      feeLevel: 'HIGH'
    }
  }
});`,
  // UCW
  'POST/v1/w3s/users':
    nodeUCWInitializeSnippet +
    `const response = await client.createUser({
  userId: '<USER_ID>'
});`,
  'POST/v1/w3s/users/token':
    nodeUCWInitializeSnippet +
    `const response = await client.createUserToken({
  userId: '<USER_ID>'
});`,
  'POST/v1/w3s/user/initialize':
    nodeUCWInitializeSnippet +
    `const response = await client.createUserPinWithWallets({
  userToken: '<USER_TOKEN>',
  blockchains: ['<BLOCKCHAIN>']
});`,
  'GET/v1/w3s/wallets?userId=__USER-ID__':
    nodeUCWInitializeSnippet +
    `const response = await client.listWallets({
  userId: '<USER_ID>'
});`,
  'POST/v1/faucet/drips': `const response = await client.requestTestnetTokens({
  address: '<YOUR_WALLET_ADDRESS>',
  blockchain: 'ETH-SEPOLIA',
  native: false,
  usdc: true,
  eurc: false
});`,
  'POST/v1/w3s/user/transactions/transfer':
    nodeUCWInitializeSnippet +
    `const response = await client.createTransaction({
  userId: '<ID_OF_THE_USER_YOU_WANT_TO_USE>',
  amounts: ['<AMOUNT_1>'],
  destinationAddress: '<DESTINATION_WALLET_ADDRESS>',
  tokenId: '<ID_OF_THE_TOKEN_YOU_WANT_TO_TRANSFER>',
  walletId: '<ID_OF_PREVIOUSLY_GENERATED_WALLET>',
  fee: {
    type: 'level',
    config: {
      feeLevel: 'MEDIUM'
    }
  }
});`,
  //SCP
  'POST/v1/w3s/developer/transactions/contractExecution':
    nodeDCWInitializeSnippet +
    `const response = await client.createContractExecutionTransaction({
  walletId: '<WALLET_ID>',
  abiFunctionSignature: '<FUNCTION_SIGNATURE>',
  abiParameters: ['<ABI_PARAMETER_1>', <ABI_PARAMETER_2>],
  contractAddress: '<CONTRACT_ADDRESS>',
  fee: {
    type: 'level',
    config: {
      feeLevel: 'MEDIUM'
    }
  }
});`,
  'GET/v1/w3s/transactions/__TRANSACTION_ID__':
    nodeDCWInitializeSnippet +
    `const response = await client.getTransaction({
  id: '<transaction-id>'
});`,
}
const pythonSDKCodeExamplesMap: { [key: string]: string } = {
  //DCW
  'GET/v1/w3s/config/entity/publicKey':
    pythonDCWInitializeSnippet +
    `# get public key
try:
  response = client.configuration.get_public_key()
  print(response)
except developer_controlled_wallets.ApiException as e:
  print("Exception when calling configuration->get_public_key: %s" % e)`,

  'POST/v1/w3s/developer/walletSets':
    pythonDCWInitializeSnippet +
    `api_instance = developer_controlled_wallets.WalletSetsApi(client)
request = developer_controlled_wallets.CreateWalletSetRequest.from_dict({
  "name": "Entity WalletSet A",
})
response = api_instance.create_wallet_set(request)
print(response)`,

  'POST/v1/w3s/developer/wallets':
    pythonDCWInitializeSnippet +
    `api_instance = developer_controlled_wallets.WalletsApi(client)
request = developer_controlled_wallets.CreateWalletRequest.from_dict({
  "accountType": 'SCA',
  "blockchains": ['BLOCKCHAIN_1'],
  "count": 2,
  "walletSetId": '<WALLET_SET_ID>'
})
response = api_instance.create_wallet(request)
print(response)`,

  'GET/v1/w3s/wallets/__WALLET_ID__/balances':
    pythonDCWInitializeSnippet +
    `api_instance = developer_controlled_wallets.WalletsApi(client)
response = api_instance.list_wallet_balance(id="<WALLET_ID>")
print(response)`,

  'POST/v1/w3s/developer/transactions/transfer':
    pythonDCWInitializeSnippet +
    `api_instance = developer_controlled_wallets.TransactionsApi(client)
request = developer_controlled_wallets.CreateTransferTransactionForDeveloperRequest.from_dict({
  "walletId": '<WALLET_ID>',
  "tokenId": '<TOKEN_ID>',
  "destinationAddress": '<DESTINATION_ADDRESS>',
  "amounts": ['<AMOUNT>'],
  "feeLevel": 'MEDIUM'
})
response = api_instance.create_developer_transaction_transfer(request)
print(response)`,
  // UCW
  'POST/v1/w3s/users':
    pythonUCWInitializeSnippet +
    `api_instance = user_controlled_wallets.PINAuthenticationApi(client)
request = user_controlled_wallets.CreateUserRequest(user_id='<USER_ID>')
response = api_instance.create_user(request)
print(response)`,
  'POST/v1/w3s/users/token':
    pythonUCWInitializeSnippet +
    `api_instance = user_controlled_wallets.PinAuthentication(client)
request = user_controlled_wallets.UserTokenRequest.from_dict({"userId": "<USER_ID>"})
response = api_instance.get_user_token(request)
print(response)`,
  'POST/v1/w3s/user/initialize':
    pythonUCWInitializeSnippet +
    `api_instance = user_controlled_wallets.UsersApi(client)
request = user_controlled_wallets.SetPinAndInitWalletRequest.from_dict({"blockchains": ['<BLOCKCHAINS>'], "idempotencyKey": str(uuid.uuid4()) })
response = api_instance.create_user_with_pin_challenge("<USER_TOKEN>", request)
print(response)`,
  'GET/v1/w3s/wallets?userId=__USER-ID__':
    pythonUCWInitializeSnippet +
    `api_instance = user_controlled_wallets.WalletsApi(client)
response = api_instance.list_wallets("<USER_ID>")
print(response)`,
  'POST/v1/faucet/drips':
    pythonUCWInitializeSnippet +
    `api_instance = utils.configurations.FaucetApi(client)
request = utils.configurations.FaucetRequest(address="0xFF6458E7C49f54Da1593238Ae081Ca5f74638336",blockchain='ETH-SEPOLIA',eurc=False,usdc=True,native=True)
response = api_instance.request_testnet_tokens(faucet_request=request)
print(response)`,
  'POST/v1/w3s/user/transactions/transfer':
    pythonUCWInitializeSnippet +
    `api_instance = user_controlled_wallets.TransactionsApi(client)
request = user_controlled_wallets.CreateTransferTransactionForEndUserRequest.from_dict({
  "userToken": '<USER_TOKEN>',
  "amounts": ['<AMOUNT>'],
  "destinationAddress": '<DESTINATION_ADDRESS>',
  "tokenId": '<TOKEN_ID>',
  "walletId": '<WALLET_ID>',
  "fee": {
      "type": 'level',
      "config": {
      "feeLevel": 'MEDIUM'
      }
  }
})
response = api_instance.create_user_transaction_transfer_challenge("<USER_TOKEN>", request)
print(response)`,
  //SCP
  'POST/v1/w3s/developer/transactions/contractExecution':
    pythonDCWInitializeSnippet +
    `# create an api instance
api_instance = developer_controlled_wallets.TransactionsApi(client)
try:
  request = developer_controlled_wallets.CreateContractExecutionTransactionForDeveloperRequest.from_dict({
      "walletId": '<WALLET_ID>',
      "contractAddress": '<CONTRACT_ADDRESS>',
      "abiFunctionSignature": '<FUNCTION_SIGNATURE>',
      "abiParameters": ['<ABI_PARAMETER_1>', '<ABI_PARAMETER_2>'],
      "feeLevel": '<FEE_LEVEL>'
  })
  response = api_instance.create_developer_transaction_contract_execution(request)
  print(response)
except developer_controlled_wallets.ApiException as e:
    print("Exception when calling TransactionsApi->create_developer_transaction_contract_execution: %s" % e)`,
  'GET/v1/w3s/transactions/__TRANSACTION_ID__':
    pythonDCWInitializeSnippet +
    `api_instance = developer_controlled_wallets.TransactionsApi(client)
response = api_instance.get_transaction(id='<TRANSACTION_ID>')`,
}

export const getSDKCodeExample = (
  client: string,
  method: string,
  path: string,
): string => {
  if (client === 'NodeJS') return nodeSDKCodeExamplesMap[method + path] ?? ''
  if (client === 'Python') return pythonSDKCodeExamplesMap[method + path] ?? ''
  return ''
}
