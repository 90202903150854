'use client'

import { useCallback, useEffect, useState } from 'react'

import { useConfig } from '@features/common.hooks/useConfig'
import classNames from 'classnames'

import { CodePanel } from '../Code'
import { CodeScaffoldHeader } from '../CodeScaffold/CodeScaffoldHeader/CodeScaffoldHeader'

import { getCode } from './utils'

import type { RequestConfig } from './types'
import type { LanguageType } from '../Code/languages'

export interface RequestTemplateProps {
  config: RequestConfig
  title?: string
  border?: boolean
}
export interface Target {
  key: string
  name: string
  clients?: string[]
}

const targets: Target[] = [
  {
    key: 'SDK',
    name: 'SDK',
    clients: ['NodeJS', 'Python'],
  },
  {
    key: 'go',
    name: 'Go',
    clients: ['native'],
  },
  {
    key: 'node',
    name: 'NodeJS',
    clients: ['fetch', 'axios', 'native', 'request', 'unirest'],
  },
  {
    key: 'java',
    name: 'Java',
    clients: ['asynchttp', 'nethttp', 'okhttp', 'unirest'],
  },
  {
    key: 'javascript',
    name: 'JavaScript',
    clients: ['axios', 'fetch', 'jquery', 'xhr'],
  },
  {
    key: 'kotlin',
    name: 'Kotlin',
    clients: ['okhttp'],
  },
  {
    key: 'php',
    name: 'PHP',
    clients: ['curl', 'guzzle', 'http1', 'http2'],
  },
  {
    key: 'python',
    name: 'Python',
    clients: ['requests'],
  },
  {
    key: 'ruby',
    name: 'Ruby',
    clients: ['native'],
  },
  {
    key: 'shell',
    name: 'Shell',
    clients: ['curl', 'httpie', 'wget'],
  },
  {
    key: 'swift',
    name: 'Swift',
    clients: ['nsurlsession'],
  },
]

export const RequestTemplate: React.FC<RequestTemplateProps> = ({
  config,
  border = true,
}) => {
  const {
    urls: { api: apiUrl },
  } = useConfig()
  const [selectedTargetKey, setSelectedTargetKey] = useState('SDK')
  const [selectedClientKey, setSelectedClientKey] = useState<
    string | undefined
  >('NodeJS')

  const selectedTarget =
    targets.find(({ key }) => key === selectedTargetKey) ?? targets[1]

  const selectedClient = selectedClientKey ?? selectedTarget.clients?.[0]

  const handleTargetSelection = useCallback(
    (targetKey: string) => {
      if (targetKey !== selectedTargetKey) {
        setSelectedClientKey(
          targets.find((t) => t.key === targetKey)?.clients?.[0],
        )
      }
      setSelectedTargetKey(targetKey)
    },
    [selectedTargetKey],
  )

  const [code, setCode] = useState('')
  useEffect(() => {
    async function main() {
      const codeResponse = await getCode(
        config,
        apiUrl,
        selectedTarget.key,
        selectedClient,
      )
      setCode(codeResponse as string)
    }
    void main()
  }, [apiUrl, config, selectedClient, selectedTarget.key])

  return (
    <div
      className={classNames({
        'border border-neutral-subtle rounded-sm px-6 py-4': border,
      })}
    >
      <CodeScaffoldHeader
        selectedClient={selectedClient}
        selectedTarget={selectedTarget}
        setSelectedClientKey={setSelectedClientKey}
        setSelectedTargetKey={handleTargetSelection}
        targets={targets}
      />
      <CodePanel
        border={border}
        code={code}
        language={
          selectedTarget.key === 'SDK'
            ? (selectedClient as LanguageType)
            : (selectedTarget.key as LanguageType)
        }
        selectedView="Code"
      />
    </div>
  )
}
