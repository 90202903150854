'use client'

import { useState, type ReactElement, useCallback } from 'react'

import { Tabs as CircleFinTabs } from '@circlefin/components'

import { Content } from './Content'

import type { ContentProps } from './Content'

export interface TabbedProps {
  children: ReactElement<ContentProps>[]
}

interface SubElements {
  Content: typeof Content
}

export const Tabbed: React.FC<TabbedProps> & SubElements = ({ children }) => {
  const [current, setCurrent] = useState(() => children[0].props.label)

  const handleClick = useCallback((next: string) => {
    setCurrent(next)
  }, [])

  return (
    <>
      <CircleFinTabs current={current} variant="underlined-black">
        {children.map((item) => (
          <CircleFinTabs.Item
            key={item.props.label}
            id={item.props.label}
            onClick={handleClick}
          >
            {item.props.label}
          </CircleFinTabs.Item>
        ))}
      </CircleFinTabs>
      {children.map((item) => (
        <Content
          key={item.props.label}
          active={item.props.label === current}
          label={item.props.label}
        >
          {item.props.children}
        </Content>
      ))}
    </>
  )
}
Tabbed.Content = Content
