import { useCallback, type FC } from 'react'

import { Toaster, useToast } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import { CWButton } from '@features/common.components/ComponentsWeb/Button'
import { FeedbackDocument } from '@features/documentation.graphql'
import { useMutation } from '@shared/apollo'
import useTranslation from 'next-translate/useTranslation'

import type { SubmitHandler } from '@circlefin/form/useForm'

export interface FeedbackTextProps {
  /**
   * Indicates if the feedback is positive (true) or negative (false).
   */
  positive: boolean
  /**
   * Function to handle the close action when the user clicks the close button.
   */
  onClose: () => void
  /**
   * The URL associated with the feedback submission, representing the page the feedback is about.
   */
  url: string
  /**
   * A unique identifier for the feedback, allowing for tracking specific feedback instances.
   */
  identifier: string
}

const schema = y.object().shape({
  text: y.string(),
  positive: y.boolean().required(),
})

type FormSchema = y.InferType<typeof schema>

export const FeedbackText: FC<FeedbackTextProps> = ({
  positive,
  onClose,
  url,
  identifier,
}) => {
  const toast = useToast()
  const { t } = useTranslation('documentation')

  const [sendFeedback, { loading }] = useMutation(FeedbackDocument, {
    onCompleted: () => {
      onClose()
      toast.success(t`feedback.form.toastMessage`)
    },
    onError: () => {},
  })

  const submitFeedback: SubmitHandler<FormSchema> = useCallback(
    (data) => {
      void sendFeedback({
        variables: {
          input: {
            url,
            identifier,
            actionType: 'pageFeedback',
            action: data.text || '',
          },
        },
      })
    },
    [identifier, sendFeedback, url],
  )

  const [Form] = useForm<FormSchema>({
    schema,
    defaultValues: { positive },
  })

  return (
    <Form onSubmit={submitFeedback}>
      <Form.Textarea
        label={t`feedback.form.title`}
        name="text"
        placeholder={
          positive
            ? t`feedback.form.placeholder.positive`
            : t`feedback.form.placeholder.negative`
        }
      />
      <div className="flex justify-end my-2 space-x-2">
        <CWButton
          className="normal-case"
          onClick={onClose}
          size="sm"
          variant="text"
        >
          {t`feedback.form.close`}
        </CWButton>
        <Form.SubmitButton
          className="normal-case"
          loading={loading}
          size="sm"
          variant="primary"
        >
          {t`feedback.form.submit`}
        </Form.SubmitButton>
      </div>
      <Toaster />
    </Form>
  )
}
