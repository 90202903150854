import type { RequestQuery, RequestQueryParam } from './types'

interface Param {
  name: string
  value: string | number | boolean
}

export const buildParams = (query: RequestQuery = {}) => {
  const params: Param[] = []

  Object.keys(query).forEach((key) => {
    appendParam(params, key, query[key])
  })

  return params
}

const appendParam = (
  params: Param[],
  key: string,
  param: RequestQueryParam,
) => {
  if (Array.isArray(param)) {
    param.forEach((p) => appendParam(params, key, p))
  } else if (typeof param === 'string') {
    params.push({
      name: key,
      value: param,
    })
  } else {
    params.push({
      name: key,
      value: param.toString(),
    })
  }
}
