'use client'

import { useCallback, useEffect, useState } from 'react'

import { ClipboardIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'

export interface CopyToClipboardProps {
  /**
   * Stringified code.
   */
  code: string
}
export const CopyToClipboard: React.FC<CopyToClipboardProps> = ({ code }) => {
  const [copyCount, setCopyCount] = useState(0)
  const copied = copyCount > 0

  useEffect(() => {
    if (copyCount > 0) {
      const timeout = setTimeout(() => setCopyCount(0), 1000)
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [copyCount])

  const handleClick = useCallback(() => {
    const copyToClipboard = async () => {
      await window.navigator.clipboard.writeText(code)
      setCopyCount((count) => count + 1)
    }

    void copyToClipboard()
  }, [code])

  return (
    <button
      className={classNames(
        'group/button absolute right-0 top-0 mt-3 mr-2 overflow-hidden rounded-full py-1 pl-2 pr-3 text-xs font-circular-medium opacity-0 backdrop-blur transition focus:opacity-100 group-hover:opacity-100 z-10',
        copied
          ? 'bg-green-400/10 ring-1 ring-inset ring-green-400/20'
          : 'bg-black-900/5 hover:bg-black-900/7.5 dark:bg-black-900/2.5',
      )}
      onClick={handleClick}
      type="button"
    >
      <span
        aria-hidden={copied}
        className={classNames(
          'pointer-events-none flex items-center gap-0.5 text-black-400 transition duration-300',
          copied && '-translate-y-1.5 opacity-0',
        )}
      >
        <ClipboardIcon className="h-4 w-4 fill-black-500/20 stroke-black-500 transition-colors group-hover/button:stroke-black-400" />
        Copy
      </span>
      <span
        aria-hidden={!copied}
        className={classNames(
          'pointer-events-none absolute inset-0 flex items-center justify-center text-green-400 transition duration-300',
          !copied && 'translate-y-1.5 opacity-0',
        )}
      >
        Copied!
      </span>
    </button>
  )
}
