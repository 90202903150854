export const LANGUAGE = {
  ts: 'TypeScript',
  js: 'JavaScript',
  go: 'Go',
  python: 'Python',
  node: 'NodeJS',
  json: 'JSON',
  bash: 'Bash',
  text: 'Text',
  NodeJS: 'NodeJS',
  Python: 'Python',
}

export type LanguageType = keyof typeof LANGUAGE

export const mapLanguage = (language: keyof typeof LANGUAGE) => {
  if (language === 'node' || language === 'NodeJS') return 'js'
  if (language === 'Python') return 'python'
  return language
}
