'use client'

import type { Dispatch, PropsWithChildren, SetStateAction } from 'react'
import { createContext, useContext, useState } from 'react'

import type { LanguageType } from './languages'

export type CodeConfigContextState = [
  LanguageType,
  Dispatch<SetStateAction<LanguageType>>,
]

const Context = createContext<CodeConfigContextState>(['node', () => null])

export const CodeConfigProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const state = useState<LanguageType>('node')

  return <Context.Provider value={state}>{children}</Context.Provider>
}

export const useCodeConfig = () => {
  return useContext(Context)
}
