import { useMemo, type FC } from 'react'

import get from 'lodash.get'

import { CodePanel } from './Code'
import { usePlaygroundContext } from './PlaygroundProvider'

export type ResultProps = {
  result?: object | string | null
  copyParams?: {
    /**
     * Path of an element that should be isolated for a better user experience.
     */
    path: string
    /**
     * Key to store the result.
     */
    key?: string
  }[]
}

export const Result: FC<ResultProps> = ({ result, copyParams }) => {
  const [, { updateFieldCache }] = usePlaygroundContext()

  useMemo(() => {
    if (result != null) {
      const keysToSave: Record<string, string> = {}
      copyParams?.forEach((param) => {
        if (param.key && typeof result === 'object' && 'response' in result) {
          keysToSave[param.key] = get(result.response, param.path) as string
        }
      })
      Object.entries(keysToSave).forEach(([key, value]) => {
        updateFieldCache(key, value)
      })
    }
  }, [copyParams, result, updateFieldCache])

  return (
    <>
      <div className="flex justify-between items-center mb-3">
        <div className="type-body-base-bold">Response</div>
      </div>
      <CodePanel
        code={result != null ? result : ''}
        language="json"
        selectedView="Result"
      />
    </>
  )
}
