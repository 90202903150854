'use client'

import { useEffect, useState } from 'react'

import { HTTPSnippet } from '@readme/httpsnippet'

import { CodePanel } from '../Code'

import { buildParams } from './utils'

import type { Target } from './RequestTemplate'
import type { RequestConfig } from './types'
import type { LanguageType } from '../Code/languages'
import type { HarRequest } from '@readme/httpsnippet'

const getCode = async (
  config: RequestConfig,
  apiUrl: string,
  target?: string,
  client?: string,
) => {
  const snippetParams: Record<string, unknown> = {
    method: config.method,
    url: new URL(config.url, apiUrl).href,
    headers: Object.keys(config.headers ?? {}).map((key) => ({
      name: key,
      value: config.headers?.[key],
    })),
    queryString: buildParams(config.query),
  }

  if (config.body) {
    snippetParams['postData'] = {
      mimeType: 'application/json',
      text: JSON.stringify(config.body),
    }
  }

  const snippet = new HTTPSnippet(snippetParams as HarRequest)

  return await snippet.convert((target as never) ?? 'node', client ?? undefined)
}

export interface CodeProps {
  config: RequestConfig
  apiUrl: string
  selectedClient: string
  selectedTarget: Target
}
export const RequestTemplateCode = ({
  apiUrl,
  config,
  selectedClient,
  selectedTarget,
}: CodeProps) => {
  const [code, setCode] = useState<string>('')
  useEffect(() => {
    async function main() {
      const codeResponse = await getCode(
        config,
        apiUrl,
        selectedTarget.key,
        selectedClient,
      )
      setCode(codeResponse as string)
    }
    void main()
  }, [apiUrl, config, selectedClient, selectedTarget.key])

  return (
    <CodePanel
      code={code}
      language={selectedTarget.key as LanguageType}
      embedded
    />
  )
}
