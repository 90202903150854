import type { FC } from 'react'

import type { BasicInputField } from './types'

export const NumberInput: FC<BasicInputField> = ({
  InputComponent,
  field: { value, defaultValue, ...field },
}) => {
  return (
    <InputComponent
      className="w-full"
      name={field.key}
      placeholder="e.g. 1"
      {...field}
      key={field.key}
      message={field.message ?? (field.required ? '' : 'Optional')}
      type="number"
    />
  )
}
