'use client'

import type { PropsWithChildren } from 'react'
import { createContext, useContext, useMemo, useState } from 'react'

export interface FieldInputCache {
  [key: string]: string | number | string[]
}

export interface PlaygroundContextState {
  data: FieldInputCache
  initiator: string | null
}

interface PlaygroundContextActions {
  setInitiator: (initiator: string) => void
  updateFieldCache: (key: string, value: string | number | string[]) => void
}

const PlaygroundContext = createContext<
  [PlaygroundContextState, PlaygroundContextActions]
>([
  { data: {}, initiator: null },
  { updateFieldCache: () => undefined, setInitiator: () => undefined },
])

export const PlaygroundProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [cache, setCache] = useState<FieldInputCache>({
    timer: new Date().toString(),
  })
  const [initiator, setInitiator] = useState<string | null>(null)

  const state: PlaygroundContextState = useMemo(
    () => ({ data: cache, initiator }),
    [cache, initiator],
  )

  const actions: PlaygroundContextActions = useMemo(
    () => ({
      updateFieldCache(key, value) {
        setCache((prev) => ({
          ...prev,
          [key]: value,
        }))
      },
      setInitiator(initiator?: string) {
        setInitiator(initiator ?? null)
      },
    }),
    [],
  )

  return (
    <PlaygroundContext.Provider value={[state, actions]}>
      {children}
    </PlaygroundContext.Provider>
  )
}

export const usePlaygroundContext = () => {
  return useContext(PlaygroundContext)
}
