import type { Field } from './types'

export const feeField: Field<'fee'> = {
  type: 'fee-group',
  key: 'fee',
  label: 'Fee',
  paramType: 'body',
  required: true,
  entries: [
    [
      {
        key: 'fee.type',
        label: 'Fee Type',
        required: true,
        type: 'string',
        value: 'absolute',
      },
      {
        key: 'fee.config.maxFee',
        label: 'Max Fee',
        required: true,
        type: 'string',
      },
      {
        key: 'fee.config.priorityFee',
        label: 'Priority Fee',
        required: true,
        type: 'string',
      },
      {
        key: 'fee.config.gasLimit',
        label: 'Gas Limit',
        required: true,
        type: 'string',
      },
    ],
    [
      {
        key: 'fee.type',
        label: 'Fee Type',
        required: true,
        type: 'string',
        value: 'level',
      },
      {
        key: 'fee.config.feeLevel',
        label: 'Fee Level',
        required: true,
        type: 'select',
        options: ['LOW', 'MEDIUM', 'HIGH'],
      },
    ],
  ],
}
export const idempotencyKeyField: Field<'idempotencyKey'> = {
  paramType: 'body',
  key: 'idempotencyKey',
  label: 'IdempotencyKey',
  required: true,
  type: 'idempotencyKey',
}
