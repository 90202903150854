'use client'

import { useCallback } from 'react'
import type { FC, PropsWithChildren } from 'react'

import { useModal } from '@circlefin/modal-router/useModal/useModal'
import { routes } from '@features/common.modals'

export const FeedbackLink: FC<PropsWithChildren> = ({ children }) => {
  const { router } = useModal()
  const handleOpenFeedback = useCallback(() => {
    router.push({
      pathname: routes.feedback,
    })
  }, [router])

  return (
    <div
      className="inline-flex"
      data-testid="feedback-link"
      onClick={handleOpenFeedback}
    >
      {children}
    </div>
  )
}
