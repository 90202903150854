'use client'

import type { FC, PropsWithChildren } from 'react'

import { FixedBanner } from '@circlefin/components/lib/FixedBanner'

import type { FixedBannerProps } from '@circlefin/components/lib/FixedBanner'

export interface BannerProps extends PropsWithChildren {
  variant: FixedBannerProps['status']
}

export const Banner: FC<BannerProps> = ({ children, variant }) => {
  return (
    <div className="py-2">
      <FixedBanner level="page" status={variant} multiline>
        <FixedBanner.Description>{children}</FixedBanner.Description>
      </FixedBanner>
    </div>
  )
}
