'use client'

import { useState, useCallback, isValidElement, cloneElement } from 'react'
import type { ReactElement } from 'react'

import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from '@heroicons/react/24/solid'
import classNames from 'classnames'

import { CarouselItem, type CarouselItemProps } from './Carousel.Item'

interface Elements {
  Item: typeof CarouselItem
}

export interface CarouselProps {
  children: ReactElement<CarouselItemProps>[]
}

export const Carousel: React.FC<CarouselProps> & Elements = ({ children }) => {
  const [step, setStep] = useState<number>(0)

  const increase = useCallback(() => {
    setStep((prev) => {
      if (prev + 1 < children.length) return prev + 1
      return prev
    })
  }, [children])

  const decrease = useCallback(() => {
    setStep((prev) => {
      if (prev > 0) return prev - 1
      return prev
    })
  }, [])

  return (
    <div className="relative flex justify-center rounded-sm border border-neutral-subtle p-2 ">
      <div className="max-w-3xl">
        <div className="mx-4 mt-4 flex overflow-hidden">
          {children.map((child, index) =>
            isValidElement(child)
              ? cloneElement<CarouselItemProps>(child, {
                  step: step,
                  key: index,
                })
              : child,
          )}
        </div>
        <button
          className="absolute -left-2 top-1/2 sm:-translate-x-1/2 md:-translate-x-full"
          disabled={step <= 0}
          onClick={decrease}
        >
          <ArrowLeftCircleIcon
            className={classNames('h-10 w-10 ', {
              'text-black-600/80 cursor-pointer': step > 0,
              'text-black-300/80': step <= 0,
            })}
          />
        </button>
        <button
          className="absolute -right-2 top-1/2 sm:translate-x-1/2 md:translate-x-full"
          disabled={step + 1 >= children.length}
          onClick={increase}
        >
          <ArrowRightCircleIcon
            className={classNames('h-10 w-10 ', {
              'text-black-600/80 cursor-pointer': step + 1 < children.length,
              'text-black-300/80': step + 1 >= children.length,
            })}
          />
        </button>
      </div>
    </div>
  )
}

Carousel.Item = CarouselItem
