'use client'

import type { FC, PropsWithChildren } from 'react'
import { Fragment } from 'react'

import { Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { XCircleIcon } from '@heroicons/react/24/solid'

export interface NotificationProps extends PropsWithChildren {
  open: boolean
  onClose: () => void
  title: string
  variant: 'success' | 'error'
}

export const Notification: FC<NotificationProps> = ({
  open,
  onClose,
  title,
  children,
  variant,
}) => {
  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-0 top-header flex items-end px-4 py-6 sm:items-start sm:p-6 z-60"
    >
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
        <Transition
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          show={open}
        >
          <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black-200/50">
            <div className="p-4">
              <div className="flex items-start">
                <div className="shrink-0">
                  {variant === 'success' ? (
                    <CheckCircleIcon
                      aria-hidden="true"
                      className="h-6 w-6 text-green-400"
                    />
                  ) : null}
                  {variant === 'error' ? (
                    <XCircleIcon
                      aria-hidden="true"
                      className="h-6 w-6 text-red-400"
                    />
                  ) : null}
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p
                    className="font-circular text-sm text-black-900"
                    data-testid="notification-title"
                  >
                    {title}
                  </p>
                  <p className="mt-1 text-sm text-black-500">{children}</p>
                </div>
                <div className="ml-4 flex shrink-0">
                  <button
                    className="inline-flex rounded-md bg-white text-black-400 hover:text-black-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={onClose}
                    type="button"
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon aria-hidden="true" className="h-5 w-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  )
}
