import { useCallback, type FC, type PropsWithChildren } from 'react'

import { Button } from '../../Button/Button'
import { Icon } from '../../Icon'
import { InputSelector } from '../FormElements/InputSelector'

import type { ExtendedFormSchemaType } from '../FormSchema'
import type { Field } from '../types'
import type { useForm } from '@circlefin/form'
import type { FormProps, SubComponents } from '@circlefin/form/Form'

export interface InputProps extends PropsWithChildren {
  identifier: string
  fields: Field[]
  formMethods: ReturnType<typeof useForm>['1']
  formComponent: FC<FormProps<ExtendedFormSchemaType>> &
    SubComponents<ExtendedFormSchemaType>
  defaultValues?: Record<string, string | undefined>
}

export const InputArea: FC<InputProps> = ({
  fields,
  formMethods,
  formComponent,
  defaultValues,
}) => {
  const clearForm = useCallback(() => {
    formMethods.reset(defaultValues)
  }, [formMethods, defaultValues])

  return (
    <>
      <div className="flex items-center justify-between mb-4">
        <div className="type-body-base-bold">Input</div>
        <div className="type-body-sm text-neutral-subtle">* = required</div>
      </div>
      <div className="w-full space-y-2">
        <InputSelector
          Form={formComponent}
          fields={fields}
          formMethods={formMethods}
        />
      </div>

      {fields.length > 0 ? (
        <div>
          <Button
            className="flex space-x-1 justify-center items-center px-2.5 py-1.5 max-h-8 mt-4"
            onClick={clearForm}
            spacing={false}
            type="reset"
            variant="text"
          >
            <span>Clear Form</span>
            <Icon className="h-4 w-4 text-interactive" name="XCircleIcon" />
          </Button>
        </div>
      ) : null}
    </>
  )
}
