'use client'

import { useEffect, useMemo } from 'react'

import { useForm } from '@circlefin/form'

import { ActionBar } from './ActionBar'
import { formSchema, type ExtendedFormSchemaType } from './FormSchema'
import { InputArea } from './InputArea'
import { PlaygroundScaffold } from './PlaygroundScaffold'
import { RequestTemplate } from './RequestTemplate/RequestTemplate'
import { Result } from './Result'
import { usePlaygroundData } from './usePlaygroundData'

import type { RequestTemplateProps } from './RequestTemplate/RequestTemplate'
import type { usePlaygroundDataProps } from './usePlaygroundData'

export interface PlaygroundPropsV2 extends usePlaygroundDataProps {
  /**
   * Title that should be displayed.
   */
  title?: string
  config?: RequestTemplateProps['config']
  copy?: {
    /**
     * Path of an element that should be isolated for a better user experience.
     */
    path: string
    /**
     * Key to store the result.
     */
    key?: string
  }[]
}

export const PlaygroundV2: React.FC<PlaygroundPropsV2> = ({
  title,
  url,
  config,
  _methodName,
  copy,
  _type,
}) => {
  const {
    fields,
    handleSubmit,
    identifier,
    loading,
    result,
    endpoint,
    method,
  } = usePlaygroundData({ url, _methodName, _type })
  const defaultValues = useMemo(() => {
    return fields.reduce((values, field) => {
      return {
        ...values,
        [field.key]: field.value,
      }
    }, {})
  }, [fields])

  const [Form, formMethods] = useForm<ExtendedFormSchemaType>({
    schema: formSchema(fields),
    defaultValues,
  })

  useEffect(() => {
    fields.forEach((item) => {
      formMethods.setValue(item.key, item.value)
    })
  }, [fields, formMethods])

  return (
    <PlaygroundScaffold
      actionArea={<ActionBar />}
      codeArea={
        config || endpoint ? (
          <RequestTemplate
            border={false}
            config={config ?? { method, url: endpoint }}
          />
        ) : (
          <></>
        )
      }
      config={{ method, url: endpoint }}
      fields={fields}
      formComponent={Form}
      formMethods={formMethods}
      handleSubmit={handleSubmit}
      identifier={identifier}
      inputArea={
        <InputArea
          defaultValues={defaultValues}
          fields={fields}
          formComponent={Form}
          formMethods={formMethods}
          identifier={identifier}
        />
      }
      loading={loading}
      result={result}
      resultArea={<Result copyParams={copy} result={result} />}
      title={title}
    />
  )
}
