import type { FC } from 'react'

import type { BasicInputField } from './types'

export const EntitySecretInput: FC<BasicInputField> = ({
  InputComponent,
  field,
}) => {
  return (
    <InputComponent
      className="w-full"
      name={field.key}
      {...field}
      key={field.key}
    />
  )
}
