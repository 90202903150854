'use client'

import type { ReactNode } from 'react'

import classNames from 'classnames'

export interface ContentProps {
  active: boolean
  label: string
  children: ReactNode
}

export const Content: React.FC<ContentProps> = ({ active, children }) => {
  return (
    <div
      className={classNames('w-full space-y-4', {
        hidden: !active,
      })}
    >
      {children}
    </div>
  )
}
