import { y } from '@circlefin/form'

export const apiKeyRule = y
  .string()
  .required()
  .matches(/TEST_API_KEY:[a-z0-9]*:[a-z0-9]*/gm, {
    name: 'testnetApiKey',
  })
export const entitySecretRule = y
  .string()
  .required()
  .matches(/^[a-z0-9]{64,64}$/gm, {
    name: 'entitySecret',
  })

export const requiredRule = y.string().required()
export const uuidRule = y.string().uuid()
export const uuid7Rule = y
  .string()
  .matches(
    /^[0-9A-F]{8}-[0-9A-F]{4}-[7|5][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
    {
      name: 'uuid',
    },
  )
export const positiveNumberRule = y
  .number()
  .required()
  .typeError('number')
  .min(0, { key: 'positive' })

export const blockchainAddressRule = y
  .string()
  .required()
  .matches(/([1-9A-HJ-NP-Za-km-z]{32,44})|(\b0x[a-fA-F0-9]{40}\b)/gm, {
    name: 'blockchainAddress',
  }) // matches for solana or ethereum address
export const userIDRule = y
  .string()
  .required()
  .min(5, { key: 'minLength', values: { min: 5 } })
  .max(50, { key: 'maxLength', values: { max: 50 } })
