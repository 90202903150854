import type { FC, PropsWithChildren } from 'react'

import classNames from 'classnames'
import Link from 'next/link'

import { CWChip } from '../ComponentsWeb/Chip'
import { CWIcon } from '../ComponentsWeb/Icon'

// eslint-disable-next-line import/extensions
import type { IconGradientProps } from '@circlefin/components/lib/Icon/Icon.Gradient/Icon.Gradient'

interface IconStyle {
  iconColor: string
  iconBGColor: string
}

export interface ButtonCardProps {
  title: string
  description: string
  href: string
  tag?: string
  icon?: {
    name: IconGradientProps['name']
    iconStyle: IconStyle
  }
}

export const ButtonCard: FC<PropsWithChildren<ButtonCardProps>> = ({
  title,
  description,
  href,
  tag,
  icon,
  children,
}) => {
  return (
    <div className="w-full items-start justify-center">
      <Link className="block h-full" data-testid="card" href={href}>
        <div className="flex h-full w-full flex-col space-y-2 rounded-md border border-neutral-subtle p-6 hover:bg-primary-subtle-hovered">
          <div className="flex items-start justify-between">
            {icon && (
              <CWIcon
                className={classNames(
                  'h-10 w-10 rounded-full p-2',
                  icon.iconStyle.iconBGColor,
                  icon.iconStyle.iconColor,
                )}
                name={icon.name}
              />
            )}
            {tag && (
              <CWChip
                className="rounded-sm border-none p-1"
                variant="default/info"
              >
                {tag}
              </CWChip>
            )}
          </div>
          <div className="text-lg text-neutral-strong font-circular-bold">
            {title}
          </div>
          <div className="text-neutral">{description}</div>
          <div>{children}</div>
        </div>
      </Link>
    </div>
  )
}
