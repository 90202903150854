'use client'

import type { PropsWithChildren } from 'react'
import { useCallback, useEffect, useState } from 'react'

import { W3SSdk as W3SSdkPROD } from '@circle-fin/w3s-pw-web-sdk'
import { useForm, y } from '@circlefin/form'
import { W3SSdk as W3SSdkSTG } from '@circlefin/w3s-pw-web-sdk'
import { Link } from '@features/common.components/Link'
import { Notification } from '@features/common.components/Notification'
import { useConfig } from '@features/common.hooks/useConfig'
import { usePlaygroundContext } from '@shared/ui/lib/components/Playgroundv2/PlaygroundProvider'
import useTranslation from 'next-translate/useTranslation'

import type { SubmitHandler } from '@circlefin/form/useForm'

const schema = y.object().shape({
  appId: y.string().required(),
  userToken: y.string().required(),
  encryptionKey: y.string().required(),
  challengeId: y.string().required(),
})

type SchemaType = y.InferType<typeof schema>

export interface WebSDKProps extends PropsWithChildren {
  inputKeys: {
    userToken: string
    encryptionKey: string
    challengeId: string
  }
  title: string
}

export const WebSDK = ({ inputKeys, title, children }: WebSDKProps) => {
  const { t } = useTranslation('quickstart')
  const [{ data: fieldDataCache }] = usePlaygroundContext()

  const config = useConfig()

  const [showFeedbackNotification, setShowFeedbackNotification] =
    useState(false)
  const [notificationSettings, setNotificationSettings] = useState<{
    variant: 'success' | 'error'
    message: string
  } | null>(null)
  const [Form, { setValue, trigger }] = useForm<SchemaType>({
    schema,
    defaultValues: {
      appId: fieldDataCache.appId as string,
      userToken: fieldDataCache[inputKeys.userToken] as string,
      encryptionKey: fieldDataCache[inputKeys.encryptionKey] as string,
      challengeId: fieldDataCache[inputKeys.challengeId] as string,
    },
  })

  useEffect(() => {
    setValue('appId', fieldDataCache.appId as string)
    setValue('userToken', fieldDataCache[inputKeys.userToken] as string)
    setValue('encryptionKey', fieldDataCache[inputKeys.encryptionKey] as string)
    setValue('challengeId', fieldDataCache[inputKeys.challengeId] as string)
    if (
      Boolean(fieldDataCache.appId) &&
      Boolean(fieldDataCache.challengeId) &&
      Boolean(fieldDataCache.encryptionKey) &&
      Boolean(fieldDataCache.userToken)
    ) {
      void trigger()
    }
  }, [
    fieldDataCache,
    inputKeys.challengeId,
    inputKeys.encryptionKey,
    inputKeys.userToken,
    setValue,
    trigger,
  ])

  const closeFeedbackNotificationOverTime = useCallback(() => {
    setShowFeedbackNotification(true)
    setTimeout(() => {
      setShowFeedbackNotification(false)
    }, 3000)
  }, [])

  const handleFeedbackNotification = useCallback(
    (open: boolean) => () => {
      setShowFeedbackNotification(open)
    },
    [],
  )

  const submit: SubmitHandler<SchemaType> = useCallback(
    (data) => {
      let sdk

      const appSettings = { appId: data.appId }
      const authentication = {
        userToken: data.userToken,
        encryptionKey: data.encryptionKey,
      }

      if (typeof window !== 'undefined') {
        if (config.env === 'stg' || config.env === 'local') {
          sdk = new W3SSdkSTG()
        } else {
          sdk = new W3SSdkPROD()
        }
      }
      if (!sdk) return

      sdk.setAppSettings(appSettings)
      sdk.setAuthentication(authentication)

      sdk.execute(data.challengeId, (error, result) => {
        if (error) {
          setNotificationSettings({
            variant: 'error',
            message: error.message,
          })
          closeFeedbackNotificationOverTime()
        }
        if (result) {
          setNotificationSettings({
            variant: 'success',
            message: 'Your challenge was successful',
          })
          closeFeedbackNotificationOverTime()
        }
      })
    },
    [closeFeedbackNotificationOverTime, config.env],
  )

  return (
    <div className="pb-6">
      <div className="relative rounded-sm bg-white shadow-sm">
        <div className="flex items-center justify-between gap-x-4 rounded-t-sm border-x border-t border-blue-400 bg-blue-400 pb-2 pl-6 pr-4 pt-3 text-sm text-blue-25">
          <div>
            <strong className="mr-1 font-circular-bold">{t`tutorials.ucw.webSDK.title`}</strong>
            <span>{title}</span>
          </div>
        </div>
        <div className="rounded-b-sm border border-black-50 px-6 py-4">
          <Form
            className="space-y-2"
            data-testid={`web-sdk-${title}-form`}
            onSubmit={submit}
          >
            <Form.Input
              className="w-full"
              data-testid="web-sdk-app-id"
              label="App Id"
              message={
                <Link
                  path="/interactive-quickstarts/user-controlled-wallets/#getting-your-app-id"
                  target="_self"
                  type="internal"
                >
                  {t`tutorials.ucw.webSDK.appIdHint`}
                </Link>
              }
              name="appId"
            />
            <Form.Input
              className="w-full"
              data-testid="web-sdk-user-token"
              label="UserToken"
              name="userToken"
            />
            <Form.Input
              className="w-full"
              data-testid="web-sdk-encryption-key"
              label="EncryptionKey"
              name="encryptionKey"
            />
            <Form.Input
              className="w-full"
              data-testid="web-sdk-challenge-id"
              label="ChallengeId"
              name="challengeId"
            />
            <div className="pt-4">{children}</div>
            <button
              className="absolute right-0 top-0 mr-5 mt-2 rounded-sm px-4 py-1 text-sm text-blue-25 font-circular-bold hover:bg-white/10"
              type="submit"
            >
              {t`tutorials.ucw.webSDK.submit`}
            </button>
          </Form>
          <Notification
            onClose={handleFeedbackNotification(false)}
            open={showFeedbackNotification}
            title={notificationSettings?.message || 'Unknown error'}
            variant={notificationSettings?.variant || 'error'}
          />
        </div>
      </div>
    </div>
  )
}
