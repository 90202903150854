'use client'

import { useCallback, type PropsWithChildren, useMemo } from 'react'

import {
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
} from '@heroicons/react/24/solid'
import classNames from 'classnames'

import {
  usePlaygroundContext,
  type ViewVariants,
} from '../../PlaygroundProvider'
import { CopyToClipboard } from '../CopyToClipboard/CopyToClipboard'

import type { LanguageType } from '../../Code/languages'

export interface CodeProps {
  selectedView: ViewVariants
  /**
   * Code that should be displayed.
   */
  code: object | string
  /**
   * Language that should be used for highlighting.
   */
  language: LanguageType
  /**
   * This optional title will only be displayed if this CodePanel is not embedded in a CodeGroup.
   */
  title?: string
  /**
   * Specify whether the header should be hidden.
   */
  embedded?: boolean
  identifier?: string
  border?: boolean
}
export const CodeScaffoldCode = ({
  code,
  selectedView,
  border,
  children,
}: PropsWithChildren<CodeProps>) => {
  const isCodeAvailable = useMemo(() => {
    return Boolean(code)
  }, [code])

  const [
    {
      config: { view },
    },
    { switchView },
  ] = usePlaygroundContext()

  const changeView = useCallback(
    (view: ViewVariants) => () => {
      if (isCodeAvailable) {
        switchView(view)
      }
    },
    [switchView, isCodeAvailable],
  )

  return (
    <div className={classNames('relative w-full', { 'mb-4': border })}>
      <div className="absolute right-2 top-2 text-neutral p-1 rounded-xs flex space-x-2 group ">
        <CopyToClipboard code={code} />
        {view === selectedView ? (
          <ArrowsPointingInIcon
            className={classNames('h-5 w-5 fill-black-75 transition-colors  ', {
              'stroke-black-500 hover:stroke-black-300 cursor-pointer':
                isCodeAvailable,
              'stroke-black-200': !isCodeAvailable,
            })}
            onClick={changeView('Overview')}
            role="img"
          />
        ) : (
          <ArrowsPointingOutIcon
            className={classNames('h-5 w-5 fill-black-75 transition-colors  ', {
              'stroke-black-500 hover:stroke-black-300 cursor-pointer':
                isCodeAvailable,
              'stroke-black-200': !isCodeAvailable,
            })}
            onClick={changeView(selectedView)}
            role="img"
          />
        )}
      </div>
      <div
        className={classNames(
          'overflow-y-scroll [transition:max-height_.3s] border border-neutral-subtle rounded-sm',
          {
            'max-h-40': view === 'Overview' || view === 'Snippets',
            'max-h-96': view === selectedView,
          },
        )}
      >
        {children}
      </div>
    </div>
  )
}
