'use client'

import classNames from 'classnames'

import { CodePanel } from '../Code'

import type { LanguageType } from '../Code/languages'

export interface CustomCodeSnippetProps {
  code: string | object
  border?: boolean
  language: LanguageType
}

export const CustomCodeSnippet: React.FC<CustomCodeSnippetProps> = ({
  code,
  language,
  border = true,
}) => {
  return (
    <div
      className={classNames({
        'border border-neutral-subtle rounded-sm px-6 py-4': border,
      })}
    >
      <div className="flex justify-between items-center mb-2">
        <div className="type-body-base-bold">Code</div>
      </div>
      <CodePanel
        border={border}
        code={code}
        language={language}
        selectedView="Code"
      />
    </div>
  )
}
