'use client'

import { Fragment, useCallback } from 'react'

import { Listbox as HeadlessListBox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'

export interface ListBoxProps<T = string | number> {
  entries: { label: string; value: T }[]
  value: T
  onSelect?: (item: T) => void
}

export const ListBox = <T = string | number,>({
  entries,
  value,
  onSelect,
}: ListBoxProps<T>) => {
  const handleChange = useCallback(
    (item: T) => {
      onSelect?.(item)
    },
    [onSelect],
  )

  return (
    <HeadlessListBox onChange={handleChange} value={value}>
      <div className="relative pb-1 pt-2">
        <HeadlessListBox.Button className="relative w-full cursor-pointer rounded-sm border border-black-100 py-1 pl-4 pr-10 text-left text-sm focus:outline-none focus-visible:border-blue-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-yellow-300">
          <span className="block truncate text-sm leading-6 text-black-900 font-circular-book">
            {entries.find((e) => e.value === value)?.label}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronDownIcon
              aria-hidden="true"
              className="h-4 w-4 text-black-400"
            />
          </span>
        </HeadlessListBox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <HeadlessListBox.Options className="absolute right-0 z-20 mt-1 max-h-60 w-full min-w-32 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
            {entries.map((entry, index) => (
              <HeadlessListBox.Option
                key={index}
                // eslint-disable-next-line react/jsx-no-bind
                className={({ active }) =>
                  `text-sm relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                    active ? 'bg-black-50 text-black-900' : 'text-black-900'
                  }`
                }
                value={entry.value}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected
                          ? 'font-circular-medium'
                          : 'font-circular-regular'
                      }`}
                    >
                      {entry.label}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-violet-600">
                        <CheckIcon aria-hidden="true" className="h-4 w-4" />
                      </span>
                    ) : null}
                  </>
                )}
              </HeadlessListBox.Option>
            ))}
          </HeadlessListBox.Options>
        </Transition>
      </div>
    </HeadlessListBox>
  )
}
