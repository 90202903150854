export const LANGUAGE = {
  ts: 'TypeScript',
  js: 'JavaScript',
  go: 'Go',
  python: 'Python',
  node: 'NodeJS',
  json: 'JSON',
  bash: 'Bash',
  text: 'Text',
  curl: 'Bash',
  javascript: 'JavaScript',
}

export type LanguageType = keyof typeof LANGUAGE

export const mapLanguage = (language: keyof typeof LANGUAGE) => {
  if (language === 'node') return 'js'
  return language
}
