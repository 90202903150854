import { ListBox } from './../../ListBox/ListBox'

import type { Target } from '../../RequestTemplate/RequestTemplate'

export interface CodeScaffoldHeaderProps {
  selectedClient?: string
  selectedTarget?: Target
  setSelectedClientKey?: (client: string) => void
  setSelectedTargetKey?: (target: string) => void
  targets: Target[]
}

export const CodeScaffoldHeader: React.FC<CodeScaffoldHeaderProps> = ({
  selectedClient,
  selectedTarget,
  setSelectedClientKey,
  setSelectedTargetKey,
  targets,
}) => {
  return (
    <div className="flex justify-between items-center mb-2">
      <div className="type-body-base-bold">Code</div>
      {targets.length > 1 ? (
        <div className="flex justify-between space-x-2">
          <ListBox<string>
            entries={targets.map((target) => ({
              label: target.name,
              value: target.key,
            }))}
            onSelect={setSelectedTargetKey}
            value={selectedTarget?.key ?? ''}
          />
          {selectedTarget?.clients &&
          selectedTarget.clients.length > 1 &&
          selectedClient ? (
            <ListBox<string>
              entries={selectedTarget.clients.map((client) => ({
                label: client,
                value: client,
              }))}
              onSelect={setSelectedClientKey}
              value={selectedClient}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  )
}
