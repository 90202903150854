import { useCallback } from 'react'
import type { ChangeEventHandler, FC } from 'react'

import Link from 'next/link'

import type { BasicInputField } from './types'
import type { Field } from '../types'
import type { useForm } from '@circlefin/form'

export const GenericInput: FC<
  BasicInputField & {
    formMethods: ReturnType<typeof useForm>['1']
  }
> = ({
  InputComponent,
  field: { value, defaultValue, ...field },
  formMethods,
}) => {
  const getMessage = (field: Field) => {
    if (field.message != null) {
      if (field.link) {
        return <Link href={field.link}>{field.message}</Link>
      } else {
        return field.message
      }
    } else {
      if (field.required) {
        return ''
      } else {
        return 'Optional'
      }
    }
  }

  const handleFieldChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target }) => {
      if (field.multiple) {
        formMethods.setValue(field.key, [target.value])
      }
    },
    [field, formMethods],
  )

  return (
    <InputComponent
      className="w-full"
      {...field}
      key={field.key}
      label={field.label}
      message={getMessage(field)}
      name={field.key}
      onChange={handleFieldChange}
    />
  )
}
