import * as HeroIcons from '@heroicons/react/20/solid'

export interface IconProps {
  name: keyof typeof HeroIcons
  className?: string
}

export const Icon: React.FC<IconProps> = ({ name, ...props }) => {
  // eslint-disable-next-line import/namespace
  const Icon = HeroIcons[name]

  return <Icon data-testid={`icon-${name}`} {...props} />
}
