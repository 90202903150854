'use client'

import { useState, type FC, type ReactNode, useCallback } from 'react'

import classNames from 'classnames'

interface OSSwitchProps {
  children: ReactNode[]
}

export const OSSwitch: FC<OSSwitchProps> = ({ children }) => {
  const [os, setOS] = useState(0)

  const switchOS = useCallback(
    (option: number) => () => {
      setOS(option)
    },
    [],
  )

  return (
    <div className="flex flex-col">
      <div className="mb-4 flex space-x-6">
        <button
          className={classNames(
            'flex h-16 w-1/2 cursor-pointer items-center justify-center rounded-sm border  text-neutral font-circular-bold hover:bg-primary-subtle-hovered',
            {
              'border-gradient-selected': os === 0,
              'border-neutral-subtle': os !== 0,
            },
          )}
          onClick={switchOS(0)}
        >
          iOS
        </button>
        <button
          className={classNames(
            'flex h-16 w-1/2 cursor-pointer items-center justify-center rounded-sm border text-neutral font-circular-bold hover:bg-primary-subtle-hovered',
            {
              'border-gradient-selected': os === 1,
              'border-neutral-subtle': os !== 1,
            },
          )}
          onClick={switchOS(1)}
        >
          Android
        </button>
      </div>
      {children[os]}
    </div>
  )
}
