import { Fragment } from 'react'

import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'

import type { Target } from './RequestTemplate'

export interface RequestTemplateHeaderProps {
  selectedClient: string
  selectedTarget: Target
  setSelectedClientKey: (client: string) => void
  setSelectedTargetKey: (target: string) => void
  targets: Target[]
  title?: string
}

export const RequestTemplateHeader: React.FC<RequestTemplateHeaderProps> = ({
  selectedClient,
  selectedTarget,
  setSelectedClientKey,
  setSelectedTargetKey,
  targets,
  title,
}) => {
  return (
    <>
      <div className="flex items-center gap-x-4 border-b border-b-black-50 pl-4 pr-2">
        {title && (
          <h4 className="mr-auto shrink-0 truncate pb-2 pl-2 pt-3 text-sm text-black-700 font-circular-bold">
            {title}
          </h4>
        )}
        <div className="ml-auto pb-1">
          <Listbox onChange={setSelectedTargetKey} value={selectedTarget.key}>
            <div className="relative pb-1 pt-2">
              <Listbox.Button className="relative w-full cursor-pointer rounded-sm border border-black-100 py-1 pl-4 pr-10 text-left text-sm focus:outline-none focus-visible:border-blue-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-yellow-300">
                <span className="block truncate text-sm leading-6 text-black-900 font-circular-book">
                  {selectedTarget.name}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronDownIcon
                    aria-hidden="true"
                    className="h-4 w-4 text-black-400"
                  />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute right-0 z-20 mt-1 max-h-60 w-full min-w-32 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                  {targets.map((target) => (
                    <Listbox.Option
                      key={target.key}
                      // eslint-disable-next-line react/jsx-no-bind
                      className={({ active }) =>
                        `text-sm relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                          active
                            ? 'bg-black-50 text-black-900'
                            : 'text-black-900'
                        }`
                      }
                      value={target.key}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected
                                ? 'font-circular-medium'
                                : 'font-circular-regular'
                            }`}
                          >
                            {target.name}
                          </span>
                          {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-violet-600">
                              <CheckIcon
                                aria-hidden="true"
                                className="h-4 w-4"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
      </div>
      <div className="flex items-center border-b border-b-black-50 bg-black-25 px-2">
        <Listbox onChange={setSelectedClientKey} value={selectedClient}>
          <div className="relative pt-1">
            <Listbox.Button className="relative w-full cursor-pointer rounded-sm py-1 pl-4 pr-6 text-left text-xs uppercase focus:outline-none focus-visible:border-blue-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-yellow-300">
              <span className="block truncate text-sm leading-6 text-black-900 font-circular-medium">
                {selectedClient}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                <ChevronDownIcon
                  aria-hidden="true"
                  className="h-4 w-4 text-black-400"
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute right-0 z-20 mt-1 max-h-60 w-full min-w-32 overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-black/5 focus:outline-none">
                {selectedTarget.clients.map((client) => (
                  <Listbox.Option
                    key={client}
                    // eslint-disable-next-line react/jsx-no-bind
                    className={({ active }) =>
                      `text-sm relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-black-50 text-black-900' : 'text-black-900'
                      }`
                    }
                    value={client}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate uppercase ${
                            selected
                              ? 'font-circular-medium'
                              : 'font-circular-regular'
                          }`}
                        >
                          {client}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                            <CheckIcon aria-hidden="true" className="h-4 w-4" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
    </>
  )
}
