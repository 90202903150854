'use client'

import { useEffect, useRef } from 'react'

export const usePreventLayoutShift = () => {
  const positionRef = useRef<HTMLElement>()
  const rafRef = useRef<number>()

  useEffect(() => {
    return () => {
      if (rafRef.current !== undefined)
        window.cancelAnimationFrame(rafRef.current)
    }
  }, [])

  return {
    positionRef,
    preventLayoutShift(callback: () => void) {
      if (positionRef.current) {
        const initialTop = positionRef.current.getBoundingClientRect().top

        callback()

        const currentPositionRef = positionRef.current

        rafRef.current = window.requestAnimationFrame(() => {
          const newTop = currentPositionRef.getBoundingClientRect().top
          window.scrollBy(0, newTop - initialTop)
        })
      }
    },
  }
}
