export const ThumbsUp = () => {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon/Outline/thumb-down">
        <path
          d="M8.33334 8.33333H4.3634C3.12443 8.33333 2.3186 9.63719 2.87269 10.7454L5.78935 16.5787C6.07167 17.1433 6.64878 17.5 7.28006 17.5H10.6282C10.7644 17.5 10.9002 17.4833 11.0324 17.4502L14.1667 16.6667M8.33334 8.33333V4.16667C8.33334 3.24619 9.07953 2.5 10 2.5H10.0796C10.4959 2.5 10.8333 2.83748 10.8333 3.25377C10.8333 3.84903 11.0095 4.43096 11.3397 4.92624L14.1667 9.16667V16.6667M8.33334 8.33333H10M14.1667 16.6667H15.8333C16.7538 16.6667 17.5 15.9205 17.5 15V10C17.5 9.07953 16.7538 8.33333 15.8333 8.33333H13.75"
          id="Icon"
          stroke="#6B6580"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  )
}
