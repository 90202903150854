'use client'

import { type PropsWithChildren } from 'react'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'

import { CodeScaffoldCode } from '../../CodeScaffold/CodeScaffoldCode/CodeScaffoldCode'
import { type ViewVariants } from '../../PlaygroundProvider'
import { mapLanguage, type LanguageType } from '../languages'

export interface CodePanelProps {
  selectedView: ViewVariants
  /**
   * Code that should be displayed.
   */
  code: object | string
  /**
   * Language that should be used for highlighting.
   */
  language: LanguageType
  /**
   * This optional title will only be displayed if this CodePanel is not embedded in a CodeGroup.
   */
  title?: string
  /**
   * Specify whether the header should be hidden.
   */
  embedded?: boolean
  identifier?: string
  border?: boolean
}

export const CodePanel: React.FC<PropsWithChildren<CodePanelProps>> = ({
  code,
  language,
  selectedView,
}: CodePanelProps) => {
  return (
    <CodeScaffoldCode
      code={code}
      language={language}
      selectedView={selectedView}
    >
      <SyntaxHighlighter
        CodeTag={Code}
        PreTag={Pre}
        language={mapLanguage(language)}
        lineNumberStyle={{ minWidth: '2em' }}
        showLineNumbers
      >
        {typeof code === 'object' ? JSON.stringify(code, null, 2) : code}
      </SyntaxHighlighter>
    </CodeScaffoldCode>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Code: React.FC<any> = ({ style, ...props }) => {
  return <code {...props} />
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Pre: React.FC<any> = ({ style, ...props }) => (
  <pre
    className="overflow-x-auto px-6 py-4 min-h-12 text-xs font-circular-light bg-code"
    {...props}
  />
)
