import { useState, type FC, useCallback, useMemo } from 'react'

import { Dropdown } from '@circlefin/components'

// eslint-disable-next-line import/no-cycle
import { InputSelector } from './InputSelector'

import type { InputSelectorProps } from './InputSelector'
import type { GroupInputField } from './types'
import type { Field } from '../types'
import type { DropdownItem } from '@circlefin/components/lib/Dropdown'

const items = [
  { label: 'absolute', value: 'absolute' },
  { label: 'gas', value: 'gas' },
  { label: 'level', value: 'level' },
]

export const FeeInput: FC<
  GroupInputField & {
    entries: Field[][]
  } & InputSelectorProps
> = ({ field, entries, Form, formMethods }) => {
  const [feeType, setFeeType] = useState<string | undefined>()

  const handleFeeTypeChange = useCallback(
    (change: DropdownItem<string>) => {
      if (change.value) {
        formMethods.resetField('fee')
        setFeeType(change.value)
        formMethods.setValue('fee.type', change.value)
        void formMethods.trigger('fee.type')
      }
    },
    [formMethods],
  )

  const selectedFee = useMemo(() => {
    if (feeType) {
      return entries
        .find((group) => group.find((entry) => entry.value === feeType))
        ?.slice(1)
    }
  }, [entries, feeType])

  return (
    <div>
      {field.label}
      <Dropdown
        items={items}
        onChange={handleFeeTypeChange}
        placeholder="Select Fee Type"
        value={feeType}
      >
        <Dropdown.Options items={items} />
      </Dropdown>
      <div className="flex flex-col divide-y-2 divide-neutral-subtle space-y-4">
        {selectedFee ? (
          <InputSelector
            Form={Form}
            fields={selectedFee}
            formMethods={formMethods}
          />
        ) : null}
      </div>
    </div>
  )
}
