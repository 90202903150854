import type { ReactElement } from 'react'
import { Children, Fragment } from 'react'

import { Listbox, Tab, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import { capitalize } from '@shared/utils'
import classNames from 'classnames'

import { LANGUAGE } from '../../languages'

import type { CodePanelProps } from '../../CodePanel/CodePanel'
import type { LanguageType } from '../../languages'

export interface CodeGroupHeaderProps {
  /**
   * Optional title that should be displayed.
   */
  title?: string
  /**
   * Panels that should be displayed inside of the code sample.
   */
  children: ReactElement<CodePanelProps> | ReactElement<CodePanelProps>[]
  /**
   * The currently selected index.
   */
  selectedIndex: number
  /**
   * Callback that will be invoked, whenever the selected index should change.
   */
  setSelectedIndex: (index: number) => void
}

export const CodeGroupHeader: React.FC<CodeGroupHeaderProps> = ({
  children,
  title,
  selectedIndex,
  setSelectedIndex,
}) => {
  const panels = Children.toArray(children) as ReactElement<CodePanelProps>[]

  if (panels.length <= 1 && !title) return null

  return (
    <div className="flex items-center gap-x-4 border-b border-b-black-50 pl-4 pr-2">
      {title && (
        <h4 className="mr-auto shrink-0 truncate pb-2 pl-2 pt-3 text-sm text-black-700 font-circular-bold">
          {title}
        </h4>
      )}
      {panels.length > 1 ? (
        <Tab.List className="ml-auto hidden lg:block lg:pr-2">
          {Children.map(panels, (panel, index) => (
            <Tab
              className={classNames(
                'text-sm px-2 pt-3 pb-2 focus:outline-none border-b-2 font-circular-book',
                {
                  'border-black-900 text-black-900': index === selectedIndex,
                  'border-transparent text-black-400 ': index !== selectedIndex,
                },
              )}
            >
              {getTitle(panel.props.language, panel.props.title)}
            </Tab>
          ))}
        </Tab.List>
      ) : (
        <div className="border-b-2 border-black-900 px-2 pb-2 pt-3 text-sm leading-6 text-black-900 font-circular-book focus:outline-none">
          {getTitle(panels[0].props.language)}
        </div>
      )}
      {panels.length > 1 ? (
        <div className="ml-auto lg:hidden">
          <Listbox onChange={setSelectedIndex} value={selectedIndex}>
            <div className="relative pb-1 pt-2">
              <Listbox.Button className="relative w-full cursor-pointer rounded-sm border border-black-100 py-1 pl-4 pr-10 text-left text-sm focus:outline-none focus-visible:border-blue-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-yellow-300">
                <span className="block truncate text-sm leading-6 text-black-900 font-circular-book">
                  {getTitle(
                    panels[selectedIndex].props.language,
                    panels[selectedIndex].props.title,
                  )}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronDownIcon
                    aria-hidden="true"
                    className="h-4 w-4 text-black-400"
                  />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute right-0 z-20 mt-1 max-h-60 w-full min-w-32 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                  {Children.map(panels, (panel, index) => (
                    <Listbox.Option
                      // eslint-disable-next-line react/jsx-no-bind
                      className={({ active }) =>
                        `text-sm relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                          active
                            ? 'bg-black-50 text-black-900'
                            : 'text-black-900'
                        }`
                      }
                      value={index}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected
                                ? 'font-circular-medium'
                                : 'font-circular-regular'
                            }`}
                          >
                            {getTitle(panel.props.language, panel.props.title)}
                          </span>
                          {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-violet-600">
                              <CheckIcon
                                aria-hidden="true"
                                className="h-4 w-4"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
      ) : null}
    </div>
  )
}

/**
 * Helper function to derive title from code panel.
 */
const getTitle = (language: LanguageType, title?: string) => {
  return title ?? LANGUAGE[language] ?? capitalize(language)
}
