import { useCallback, type FC, type ReactNode } from 'react'

import { Icon } from '@circlefin/components'
import classNames from 'classnames'

import { usePlaygroundContext } from '../PlaygroundProvider'

import type { ExtendedFormSchemaType } from '../FormSchema'
import type { RequestConfig } from '../RequestTemplate/types'
import type { Field } from '../types'
import type { useForm } from '@circlefin/form'
import type { FormProps, SubComponents } from '@circlefin/form/Form'
import type { SubmitHandler } from '@circlefin/form/useForm'

export type PlaygroundScaffoldProps = {
  fields: Field[]
  identifier: string
  config: RequestConfig
  handleSubmit: (values: Record<string, unknown>) => Promise<void>
  result: object | string | null
  title?: string
  inputArea: ReactNode
  codeArea: ReactNode
  resultArea: ReactNode
  actionArea: ReactNode
  formComponent: FC<FormProps<ExtendedFormSchemaType>> &
    SubComponents<ExtendedFormSchemaType>
  loading: boolean
  formMethods: ReturnType<typeof useForm>['1']
}

export const PlaygroundScaffold: FC<PlaygroundScaffoldProps> = ({
  identifier,
  handleSubmit,
  inputArea,
  codeArea,
  resultArea,
  title,
  actionArea,
  formComponent,
  loading,
  formMethods,
}) => {
  const [
    {
      config: { view },
    },
  ] = usePlaygroundContext()

  const Form = formComponent

  const onSubmit: SubmitHandler<ExtendedFormSchemaType> = useCallback(
    async (data) => {
      await handleSubmit(data)
      formMethods.reset()
    },
    [formMethods, handleSubmit],
  )

  return (
    <div className="py-2">
      <div className="border border-neutral-subtle rounded-sm">
        {title ? (
          <div className="h-14 px-6 py-4 border-b border-neutral-subtle type-h-title-lg">
            {title}
          </div>
        ) : null}
        <Form data-testid={`${identifier} Form`} onSubmit={onSubmit}>
          {loading ? (
            <div className="h-64 flex items-center justify-center">
              <Icon.Circle
                className="animate-spin"
                intent="accent-blue"
                name="CircleSpinnerSolid"
              />
            </div>
          ) : (
            <>
              <div className="flex">
                <div
                  className={classNames(
                    'ease-in-out max-h-116 overflow-y-scroll',
                    {
                      'w-full md:w-1/4 opacity-100 [transition:opacity_1s,width_0.5s,padding_.2s] px-6 py-3 ':
                        view === 'Overview',
                      'w-0 opacity-0 [transition:opacity_0.2s,width_0.5s,padding_1s] p-0 h-0':
                        view === 'Code' ||
                        view === 'Result' ||
                        view === 'Snippets',
                    },
                  )}
                >
                  {inputArea}
                </div>
                <div
                  className={classNames(
                    'transition-all duration-500 border-l border-neutral-subtle grow',
                    {
                      'md:w-3/4 w-0 md:opacity-100 opacity-0':
                        view === 'Overview',
                      'w-full border-none':
                        view === 'Code' ||
                        view === 'Result' ||
                        view === 'Snippets',
                    },
                  )}
                >
                  <div
                    className={classNames('ease-in-out', {
                      'max-h-200 mb-6 px-6 [transition:opacity_0s,max-height_0.3s,padding_.1s]':
                        view === 'Overview' ||
                        view === 'Code' ||
                        view === 'Snippets',
                      'max-h-0 opacity-0 p-0 [transition:opacity_0s,max-height_0.3s,padding_.1s]':
                        view === 'Result',
                    })}
                  >
                    {codeArea}
                  </div>
                  <div
                    className={classNames('ease-in-out', {
                      'max-h-200 mb-6 px-6 opacity-100 [transition:opacity_0s,max-height_0.3s,padding_.1s]':
                        view === 'Overview' ||
                        view === 'Result' ||
                        view === 'Snippets',
                      'max-h-0 opacity-0 p-0 [transition:opacity_0s,max-height_0.3s,padding_.1s]':
                        view === 'Code',
                      'pt-2': view === 'Result',
                    })}
                  >
                    {resultArea}
                  </div>
                </div>
              </div>
              <div
                className={classNames(
                  'py-4 px-6 border-t border-neutral-subtle flex',
                  {
                    'justify-between md:justify-end':
                      view === 'Overview' || view === 'Snippets',
                    'justify-start': view === 'Code',
                  },
                )}
              >
                {actionArea}
              </div>
            </>
          )}
        </Form>
      </div>
    </div>
  )
}
