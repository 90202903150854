import type { ComponentPropsWithRef, PropsWithChildren } from 'react'

import classNames from 'classnames'

export interface ButtonProps extends ComponentPropsWithRef<'button'> {
  variant: 'primary' | 'secondary' | 'text'
  spacing?: boolean
}
export const Button: React.FC<PropsWithChildren<ButtonProps>> = ({
  variant,
  className,
  spacing = true,
  ...props
}) => {
  const classes = classNames(
    'inline-flex items-center justify-center relative outline-none type-label-md rounded-sm disabled:opacity-50 disabled:pointer-events-none',
    {
      'text-invert bg-bold-interactive hover:bg-bold-interactive-hovered focus:bg-bold-interactive-hovered':
        variant === 'primary',
      'text-interactive bg-primary-subtle border-interactive-strong border box-border hover:bg-primary-subtle-hovered focus:bg-primary-subtle-hovered':
        variant === 'secondary',
      'text-interactive bg-primary-subtle box-border hover:bg-primary-subtle-hovered focus:bg-primary-subtle-hovered':
        variant === 'text',
      // Sizing information added as this if we want to allow multiple sizes moving forward
      'px-4 py-2.5 max-h-10': spacing,
    },
    className,
  )

  return <button className={classes} {...props} />
}
