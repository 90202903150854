import type { Fields, UserFuncs } from './types'

export const userFields: Pick<Fields<UserFuncs>, 'listUsers'> = {
  listUsers: {
    apiKey: true,
    entitySecret: false,
    endpoint: 'https://api.circle.com/v1/w3s/users',
    method: 'GET',
    fields: {
      pinStatus: {
        type: 'select',
        key: 'pinStatus',
        label: 'Pin Status',
        options: ['ENABLED', 'UNSET', 'LOCKED'],
        paramType: 'body',
        required: false,
      },
    },
  },
}
