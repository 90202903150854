import { y } from '@circlefin/form'

import {
  apiKeyRule,
  blockchainAddressRule,
  entitySecretRule,
  positiveNumberRule,
  requiredRule,
  userIDRule,
  uuid7Rule,
  uuidRule,
} from '../FormRules'

import type { Field } from '../types'

export const formSchema = (fields: Field[]) => {
  const validationObject: y.ObjectShape = {}

  fields.forEach((field) => {
    if (field.validation != null) {
      validationObject[field.key] = getValidation(field.validation)
    }
  })

  return y.object().shape(validationObject)
}

const getValidation = (validation: Validation) => {
  if (typeof validation === 'object') return validation
  switch (validation) {
    case 'ApiKey':
      return apiKeyRule
    case 'EntitySecret':
      return entitySecretRule
    case 'Required':
      return requiredRule
    case 'UUID':
      return uuidRule
    case 'UUID7':
      return uuid7Rule
    case 'BlockchainAddress':
      return blockchainAddressRule
    case 'PositiveNumber':
      return positiveNumberRule
    case 'userCUserId':
      return userIDRule
  }
}

export type FormSchemaType = y.InferType<ReturnType<typeof formSchema>>
export type ExtendedFormSchemaType = FormSchemaType & Record<string, unknown>
export type Validation =
  | 'ApiKey'
  | 'EntitySecret'
  | 'Required'
  | 'UUID'
  | 'UUID7'
  | 'PositiveNumber'
  | 'BlockchainAddress'
  | 'userCUserId'
  | y.AnySchema
